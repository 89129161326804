import Vue from "vue";

import { ImagePlugin, FormPlugin, ButtonPlugin, FormGroupPlugin, FormInputPlugin, ModalPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(ImagePlugin);
Vue.use(FormPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(ModalPlugin);
